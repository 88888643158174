import { Component, HostListener, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @HostListener("contextmenu", ["$event"])
  onRightClick(event) {
    if (environment.production) {
      event.preventDefault();
    }
  }
  isLoading: boolean;
}
