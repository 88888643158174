import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Table } from 'primeng/table';
import { Observable, Subject, catchError, concat, debounceTime, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { PatientSearchResult } from 'src/app/core/models/appointment.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EnumerationItem } from 'src/app/core/services/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patient-search-modal',
  templateUrl: './patient-search-modal.component.html',
  styleUrls: ['./patient-search-modal.component.scss']
})
export class PatientSearchModalComponent implements OnInit, AfterViewInit {
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('patientSearchTable') patientSearchTable: Table | undefined;
  patientsList$: Observable<PatientSearchResult[]>;
  loadingPatients = false;
  patientsInput$ = new Subject<string>();

  constructor(public bsModalRef: BsModalRef,
    private readonly _appointmentService: AppointmentService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService
  ) {
  }

  trackByFn(item: EnumerationItem) {
    return item.value;
  }

  ngOnInit(): void {
    this.searchPatients();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 50);
  }

  private searchPatients() {
    this.patientsList$ = concat(
      of(null),
      this.patientsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(400),
        tap(() => {
          this.loadingPatients = true;
          if (this.patientSearchTable?.first) {
            this.patientSearchTable.first = 0;
          }
        }),
        switchMap(term => this._appointmentService.searchPatientByPartialName(term)
          .pipe(
            catchError(() => of(null)),
            tap(() => this.loadingPatients = false)
          ))
      )
    );
  }

  openPatientDetails(patientId: string) {
    const url = this._authService.appendEncryptedAuthInfo(environment.phiAppUrl, patientId);
    window.open(url, '_blank');
  }

  onUserInput(event: Event) {
    const userInput: string = (event.target as HTMLInputElement).value;
    if (userInput?.length < 3) {
      this.patientsInput$.next(null);
      return;
    }
    this.patientsInput$.next(userInput);
  }

  hasSearchTerm(searchField: HTMLInputElement): boolean {
    return !!searchField.value;
  }
}