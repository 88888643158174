import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./components/loader/loader.component";
import { PagetitleComponent } from "./components/pagetitle/pagetitle.component";
import { ToastrModule } from "ngx-toastr";
import { PhonePipe } from "../core/pipes/phone.pipe";
import { DateWithoutTimezonePipe } from "../core/pipes/date-without-timezone.pipe";
import { SessionTimeoutComponent } from "./components/session-timeout/session-timeout.component";
import { UploadImageComponent } from "./components/upload-image/upload-image.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";

@NgModule({
  declarations: [
    LoaderComponent,
    PagetitleComponent,
    PhonePipe,
    DateWithoutTimezonePipe,
    SessionTimeoutComponent,
    UploadImageComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    ToastrModule.forRoot({ timeOut: 3000 }),
  ],
  exports: [
    LoaderComponent,
    PagetitleComponent,
    PhonePipe,
    DateWithoutTimezonePipe,
    SessionTimeoutComponent,
    UploadImageComponent,
  ],
})
export class SharedModule {}
