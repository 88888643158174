import { DateTime } from "luxon";

export class Utils {
  static parseJwt(token: string): any {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static getTokenExpireDate(exp: number) {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  }

  static splitCamelCase(value: string | undefined): string {
    return value?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '';
  }

  static toTitleCase(value: string): string {
    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  static formatPhoneNumber = (number: string): string => {
    let cleaned = ('' + number).replace(/\D/g, ''),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{3}|\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return number;
  }

  static getModalityList(): EnumerationItem[] {
    return [
      { displayName: 'XA', value: 'XA' },
      { displayName: 'IVUS', value: 'IVUS' }
    ];
  }

  static formatISODateWithoutTime(date: Date) {
    let month: number | string = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let day: number | string = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + "-" + month + "-" + day + "T00:00:00";
  }

  static getStartOfDayISODate(date: Date): string {
    if (!date) {
      return '';
    }
    const dateString = new Date(date.setHours(0, 0, 0, 0)).toISOString()
    return dateString;
  }

  static getEndOfDayISODate(date: Date): string {
    if (!date) {
      return '';
    }
    const dateString = new Date(date.setHours(23, 59, 59, 59)).toISOString()
    return dateString;
  }

  static getStartOfWeek() {
    return DateTime.now().startOf('week').startOf('day').toJSDate();
  }

  static getEndOfWeek() {
    return DateTime.now().endOf('week').endOf('day').toJSDate();
  }
  static getStartOfMonth() {
    return DateTime.now().startOf('month').startOf('day').toJSDate();
  }

  static getEndOfMonth() {
    return DateTime.now().endOf('month').endOf('day').toJSDate();
  }

  static getEnumerationItemsFromVisitTypeEnum() {
    return Object.keys(VisitType)
      .map(key => ({ value: VisitType[key], displayName: VisitType[key] }))
  }

  static getEnumerationItemsFromGenderEnum() {
    return Object.keys(Gender)
      .map(key => ({ value: Gender[key], displayName: key }))
  }

  static getEnumerationItemsDurationListEnum(): EnumerationItem[] {
    return [
      { value: DurationList.FifteenMins, displayName: '15 Mins' },
      { value: DurationList.HalfHour, displayName: '30 Mins' },
      { value: DurationList.FourtyFiveMins, displayName: '45 Mins' },
      { value: DurationList.OneHour, displayName: '60 Mins' }
    ];
  }
}
export interface EnumerationItem {
  displayName: string;
  value: string | number;
  children?: EnumerationItem[];
}

export enum VisitType {
  HomeVisit = 'Home Visit',
  OfficeVisit = 'Office Visit',
  GroupHomeVisit = 'Group Home Visit',
  TeleHealth = 'Telehealth'
}

export enum DurationList {
  FifteenMins = 15,
  HalfHour = 30,
  FourtyFiveMins = 45,
  OneHour = 60
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}