import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Dashboard",
    iconUrl: "assets/images/custom/dashboard/dashboard.svg",
    activeIconUrl: "assets/images/custom/dashboard/dashboard.svg",
    link: "/",
    width: 18,
  },
  {
    id: 2,
    label: "Encounters",
    iconUrl: "assets/images/custom/sidebar/admissions.svg",
    activeIconUrl: "assets/images/custom/sidebar/admissions-active.svg",
    link: "/referrals",
    width: 16,
  },
  // {
  //   id: 3,
  //   label: "Procedures",
  //   iconUrl: "assets/images/custom/sidebar/procedures.svg",
  //   activeIconUrl: "assets/images/custom/sidebar/procedures_active.svg",
  //   link: "/procedures",
  //   width: 20,
  // },
];
