import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Observable, of } from 'rxjs';
import { AuthToken } from '../models/auth.models';
import { Utils } from './utils';
import { AvailableApplication } from '../models/environment.interface';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    constructor(
        private readonly _localStorageService: LocalStorageService,
        private readonly _dataService: DataService) {
    }

    getNewAccessToken(refreshToken: string): Observable<AuthToken> {
        const url = this.adminApiBaseUrl + 'api/v1/auth/refresh-token';
        return this._dataService.post(url, { refreshToken });
    }

    getLoggedInUser(): Observable<User> {
        const url = this.adminApiBaseUrl + 'api/v1/auth/currentUser';
        return this._dataService.get(url);
    }

    getUserCompanies(userId: string): Observable<UserCompany[]> {
        const url = `${this.adminApiBaseUrl}api/v1/users/${userId}/companies`;
        return this._dataService.get(url);
    }

    getUserLicenses(): Observable<CompanyProductLicense[]> {
        const url = `${this.adminApiBaseUrl}api/v1/companies/company_licenses`;
        return this._dataService.get(url);
    }

    getAuthTokenForCompany(userId: string, companyId: string): Observable<AuthToken> {
        return this._dataService.post(`${this.adminApiBaseUrl}api/v1/auth/company_auth`, {
            userId,
            companyId
        });
    }

    hideOrPinCompany(request: any): Observable<void> {
        return this._dataService.post(`${this.adminApiBaseUrl}api/v1/users/pin-hide-company`, request);
    }

    logoutAllApps(): Observable<void> {
        return this._dataService.post(`${this.adminApiBaseUrl}api/v1/auth/logout`, null);
    }

    deviceSessionCheck(): Observable<AuthToken> {
        if (!this._localStorageService.machineId) {
            return of(null);
        }
        return this._dataService.get(`${this.adminApiBaseUrl}api/v1/auth/check-session/${this._localStorageService.machineId}`);
    }

    get authToken(): string {
        if (!this._localStorageService.authorizationData) {
            return null;
        }
        return this._localStorageService.authorizationData.token;
    }

    get refreshToken(): string {
        if (!this._localStorageService.authorizationData) {
            return null;
        }
        return this._localStorageService.authorizationData.refreshToken;
    }

    get phiApiBaseUrl(): string {
        return environment.apiBaseUrl;
    }

    get adminApiBaseUrl(): string {
        return environment.adminApiBaseUrl;
    }

    logout(loggedOutFromAllApps: boolean = false): void {
        if (this._localStorageService.authorizationData) {
            this._localStorageService.authorizationData = null;
        }
        if (loggedOutFromAllApps) {
            window.location.href = `${environment.loginUrl}/logout`;
            return;
        }
        this.redirectToLogin(0);
    }

    redirectToLogin(timeoutSecs: number = 1000) {
        setTimeout(() => {
            window.location.href = `${environment.loginUrl}?app=${AvailableApplication.ClinicV3}`;
        }, timeoutSecs);
    }

    get apiBaseUrl(): string {
        return environment.apiBaseUrl;
    }

    persistAuthData = (token: AuthToken): void => {
        let jwtPayload = Utils.parseJwt(token.accessToken);
        const authData = {
            token: token.accessToken,
            refreshToken: token.refreshToken,
            expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
        };

        this._localStorageService.authorizationData = authData;
    }

    appendEncryptedAuthInfo(url: string): string {
        const queryParams = [
            `q=${encodeURIComponent(this._localStorageService.authorizationDataEncrypted)}`
        ];
        if (this._localStorageService.machineId) {
            queryParams.push(`d=${encodeURIComponent(this._localStorageService.machineId)}`);
        }
        return `${url}/app-redirect?${queryParams.join('&')}`;
    }
}

export interface AuthData {
    token: string;
    refreshToken: string;
    expiresAt: Date;
}

export class User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    designation: string;
    title: string;
    status: UserStatus;
    type: UserType;
    globalAdmin: boolean;
    isOwner: boolean;
    npi: string;
    companyName: string;
    companyId: string;
    constructor(userType: UserType = UserType.BillingPhysician, title: string = '') {
        this.type = userType;
        this.title = title;
    }
}

export interface UserCompany {
    userId: string;
    isPinned: boolean;
    isHidden: boolean;
    companyId: string;
    companyName: string;
}

export interface CompanyProductLicense {
    subscriptionStatus: SubscriptionStatus;
    name: string;
    isEnabled: boolean;
    qty: number;
    totalAllowedBillingPhysicians: number;
    activeBillingPhysicians: number;
    purchaseChannel: PurchaseChannel;
    percentValue: number;
}

export enum SubscriptionStatus {
    Active = 1,
    Suspended
}

export enum PurchaseChannel {
    CommercialDirect = 1,
    CommercialIndirect
}

export enum UserStatus {
    Active = 1,
    Pending,
    Suspended
}

export enum UserType {
    BillingPhysician = 1,
    Nurse,
    MedicalAssistent,
    NonMedicalPersonal
}