import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { PatientDoc, UploadPatientDoc } from "../models/patient-docs.model";
import { ApplicationCacheService } from "./application-cache.service";
import { ApiStatus, PromptType, ReferralApis, Utils } from "./utils";
import { cloneDeep } from "lodash";
import { ToastrService } from "ngx-toastr";
import { HpiFindings, HpiSummary } from "../models/hpi-findings";
import { Medication } from "../models/procedure.model";
import { Allergy } from "../models/allergy.model";
@Injectable({
  providedIn: "root",
})
export class PatientDocsService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _toastService: ToastrService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {}

  private deleteSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  idToBeDeleted$: Observable<string> = this.deleteSubject$.asObservable();

  savePatientDoc(payload: UploadPatientDoc): Observable<PatientDoc> {
    // this is an extra endpoint because api/v1/documents does not return the inserted record
    //if case we need to return the inserted record we can use this endpoint
    //if you want all patient records returned, use the api/v1/documents api
    const url = this.apiBaseUrl + `api/v1/documents/add`;
    return this._dataService.post(url, payload);
  }

  getEncounterDocs(encounterId: string): Observable<PatientDoc[]> {
    if (this.isEncounterDocsAlreadyLoaded) {
      return of(this.documentCache);
    }
    const url = this.apiBaseUrl + `api/v1/documents/encounter/${encounterId}`;
    return this._dataService.get(url).pipe(
      tap((patientDocs: PatientDoc[]) => {
        const docs = patientDocs.map((doc) => ({
          ...doc,
          isProcedureDocument: false, // phi currently does not supports procedural docs.
        }));
        this._applicationCacheService.setDocumentCache(docs);
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ReferralApis.Documents
        );
      }),
      catchError((error) => {
        this._toastService.error(
          error || "Unable to get Patient docs due to unknown reason.",
          "Error!"
        ),
          this._applicationCacheService.setApiStatus(
            ApiStatus.Failed,
            ReferralApis.Documents
          );
        return throwError(() => error);
      })
    );
  }

  deletePatientDoc(documentId: string): Observable<void> {
    this.deleteSubject$.next(documentId);
    const url = this.apiBaseUrl + `api/v1/documents/${documentId}`;
    return this._dataService
      .delete(url)
      .pipe(finalize(() => this.deleteSubject$.next(null)));
  }

  getDocumentLinkByFileName(fileName: string): Observable<string> {
    const url = this.apiBaseUrl + `api/v1/documents/view?key=${fileName}`;
    return this._dataService.get(url, { responseType: "text" });
  }

  readFileAndFetchDetailsJson(
    fileKeyUrl: string,
    promptType: PromptType
  ): Observable<any> {
    const fileKey = Utils.extractFileKeyFromUrl(fileKeyUrl);
    const prompt = Utils.getPromptByType(promptType);

    const url = this.apiUrlForAI + "api/v1/ai/ocr/fileKey";
    return this._dataService.post(url, { fileKey, prompt });
  }

  readFileAndFetchDetailsText(
    fileKeyUrl: string,
    promptType: PromptType
  ): Observable<string> {
    const fileKey = Utils.extractFileKeyFromUrl(fileKeyUrl);
    const prompt = Utils.getPromptByType(promptType);

    const url = this.apiUrlForAI + "api/v1/ai/ocr/fileKey";
    return this._dataService.postPlainText(url, { fileKey, prompt });
  }

  fetchHPIResultWithAI(hpiQuery: string): Observable<string> {
    const url = this.apiUrlForAI + "api/v1/ai/hpi";
    return this._dataService.post(url, { hpiQuery });
  }

  generatePreAnesthsiaSummaryUsingAI(
    hpiKey: string,
    abiKey: string,
    ultrasoundKey: string
  ) {
    return forkJoin({
      hpi: this.readFileAndFetchDetailsText(hpiKey, PromptType.HPI),
      abi: abiKey
        ? this.readFileAndFetchDetailsText(abiKey, PromptType.NonInvasive)
        : of(""),
      ultrasound: ultrasoundKey
        ? this.readFileAndFetchDetailsText(ultrasoundKey, PromptType.Ultrasound)
        : of(""),
    }).pipe(
      switchMap(({ hpi, abi, ultrasound }) =>
        forkJoin({
          
        })
      ),
      catchError((error) => {
        this._toastService.error(
          error ||
            "Unable to get pre anesthesia summary due to unknown reason.",
          "Error!"
        );
        return throwError(() => error);
      })
    );
  }

  getHpiSummary(procedureId: string): Observable<HpiFindings> {
    const url = this.apiBaseUrl + `api/v1/hpi/procedure/${procedureId}`;
    return this._dataService.get(url);
  }

  private saveMeds(
    procedureId: string,
    patientId: string,
    payload
  ): Observable<Medication[]> {
    const url =
      this.apiBaseUrl +
      `api/v1/medications/map/patient/${patientId}/procedure/${procedureId}`;
    return this._dataService.post(url, payload).pipe(
      tap((response) => {
        this._applicationCacheService.setMedicationReconciliationCache(
          response
        );
      }),
      catchError((error) => {
        this._toastService.error(
          error || "Unable to save meds due to unknown reason.",
          "Error!"
        );
        return throwError(() => error);
      })
    );
  }

  private saveAllergies(
    procedureId: string,
    patientId: string,
    payload
  ): Observable<Allergy[]> {
    const url =
      this.apiBaseUrl +
      `api/v1/allergies/map/patient/${patientId}/procedure/${procedureId}`;
    return this._dataService.post(url, payload).pipe(
      tap((response) => {
        this._applicationCacheService.setAllergiesCache(response);
      }),
      catchError((error) => {
        this._toastService.error(
          error || "Unable to save allergies due to unknown reason.",
          "Error!"
        );
        return throwError(() => error);
      })
    );
  }

  saveMedsAndAllergies(
    procedureId: string,
    patientId: string,
    medsAndAllergies
  ): Observable<{
    meds: Medication[];
    allergies: Allergy[];
  }> {
    return forkJoin({
      meds: this.saveMeds(procedureId, patientId, medsAndAllergies),
      allergies: this.saveAllergies(procedureId, patientId, medsAndAllergies),
    });
  }

  get isEncounterDocsAlreadyLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(ReferralApis.Documents) ===
      ApiStatus.Success
    );
  }

  get isPreAnesthsiaSummaryAlreadyLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(
        ReferralApis.PreAnesthsiaSummary
      ) === ApiStatus.Success
    );
  }

  get documentCache(): PatientDoc[] {
    return cloneDeep(this._applicationCacheService.applicationCache.documents);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  get apiUrlForAI(): string {
    return environment.aiApiUrl;
  }
}
