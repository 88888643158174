import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Clinic',
        iconUrl: 'assets/images/custom/sidebar/studies.svg',
        activeIconUrl: 'assets/images/custom/sidebar/studies-active.svg',
        link: '/',
        width:18
    }
];

