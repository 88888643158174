import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import {
  Appointment,
  AppointmentPatient,
  ImageUpload,
  Patient,
  PatientSearchResult,
  Provider,
} from "../models/appointment.model";

@Injectable({
  providedIn: "root",
})
export class AppointmentService {
  constructor(private readonly _dataService: DataService) {}

  searchPatientByPartialName(
    searchTerm: string
  ): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  insertPatient(patient: AppointmentPatient): Observable<Patient> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.post(url, patient);
  }

  insertChiefComplaint(patientId: string, chiefComplaint: string) {
    const url = this.apiBaseUrl + "api/v1/physical-assessments/patient";
    return this._dataService.post(url, { patientId, chiefComplaint });
  }

  insertAppointment(appointment: Appointment): Observable<any> {
    const url = this.apiBaseUrl + `api/v1/encounters`;
    return this._dataService.post(url, appointment);
  }

  updateAppointment(
    appointmentId: string,
    appointment: Appointment
  ): Observable<any> {
    const url = this.apiBaseUrl + `api/v1/encounters/${appointmentId}`;
    return this._dataService.post(url, appointment);
  }

  getPatientByFirstNameLastNameAndDob(
    firstName: string,
    lastName: string,
    dob: string
  ): Observable<any> {
    const url =
      this.apiBaseUrl +
      `api/v1/patients/firstName/${firstName}/lastName/${lastName}/dob/${dob}`;
    return this._dataService.get(url);
  }

  getProviders(): Observable<Provider[]> {
    const url = `${this.accountsApiBaseUrl}api/v1/users/providers`;
    return this._dataService.get(url);
  }

  getEventsByProviderId(providerId: string): Observable<Appointment[]> {
    const url = `${this.apiBaseUrl}api/v1/encounters/provider/${providerId}`;
    return this._dataService.get(url);
  }

  getPatientImage(patientImage: ImageUpload) {
    if (!patientImage) {
      return '/assets/images/custom/patient-avatar.png';
    }
    return `data:${patientImage.contentType};base64, ${patientImage.base64String}`;
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  get accountsApiBaseUrl(): string {
    return environment.adminApiBaseUrl;
  }
}
